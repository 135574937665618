<template>
  <v-container fluid>
    <search-form @submit="handleSearchFormSubmit"/>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="patients"
      class="mt-1"
      item-key="id"
      show-select
      single-select
      hide-default-footer
    >
      <template v-slot:item.gender="{ item }">
        {{ $vuetify.lang.t(`$vuetify.gender.${ item.gender.toLowerCase() }`) }}
      </template>

      <template v-slot:item.address="{ item }">

        {{ item.address.replaceAll(',', '-') }}
      </template>
    </v-data-table>
    <v-pagination
      v-model="queryParams.params.page"
      :length="queryParams.params.pageCount"
    />
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  import bus from '@/services/bus-event';

  export default {
    components: {
      SearchForm: () => import('./SearchForm')
    },
    data () {
      return {
        selected: [],
        dialogEquipmentList: false,
        queryParams: {
          params: {
            page: 1,
            pageCount: 1
          },
          data: {
            status: 'UNSIGNED'
          }
        }
      }
    },
    computed: {
      ...mapGetters({
        patients: 'patient/patients'
      }),
      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.common.name'),
            align: 'start',
            sortable: false,
            value: 'patientName',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.gender'),
            align: 'start',
            sortable: false,
            value: 'gender',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.birthday'),
            align: 'start',
            sortable: false,
            value: 'birthday',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.address'),
            align: 'start',
            sortable: false,
            value: 'address',
          }
        ]
      }
    },

    watch: {
      'queryParams.params.page': {
         handler: function(newValue) {
            let params = Object.assign({}, this.queryParams.params)
            params.page = newValue - 1
            this.patientQuery({params: params, data: this.queryParams.data }).then( (res) => {
              this.queryParams.params.pageCount = res.totalPages
            })
         }
      },

      selected: {
        handler: function(newValue) {
          bus.emit('patient-change', newValue)
        }
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      ...mapActions ({
        patientQuery: 'patient/query'
      }),

      initialize() {
        let params = Object.assign({}, this.queryParams.params)
        params.page--
        this.patientQuery({params: params, data: this.queryParams.data }).then( (res) => {
          this.queryParams.params.pageCount = res.totalPages
        })
      },

      handleDistribute  () {
        this.dialogEquipmentList = true
      },

      handleSearchFormSubmit(data) {
        this.queryParams.data = Object.assign({ status: 'UNSIGNED'}, data)
        let params = Object.assign({}, this.queryParams.params)
        params.page = 0
        this.patientQuery({params: params, data: this.queryParams.data }).then( (res) => {
          this.queryParams.params.pageCount = res.totalPages
        })
      }
    }
  }
</script>
